.docPage {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 80px);
   margin-left: 80px;
}

.folderContainer {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.folderContainer > h4 {
   font-size: 1.2rem;
}

.fileStructurePreview {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.folderComponentList {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}

.folderComponent {
   width: 150px;
   display: flex;
   flex-direction: column;
   margin-right: 1rem;
   justify-content: center;
   transition: 0.3s;
}

.folderComponent:hover {
   cursor: pointer;
   background-color: rgb(229, 229, 229);
}

.folderIcon {
   height: 100px;
}

.folderDescription {
   max-width: 250px;
   font-size: 0.8rem;
   display: inline-block;
   text-align: center;
}

.newBreadCrumb {
   display: inline-block;
   font-size: 0.8rem;
   color: rgb(98, 97, 97);
   margin: 0 1rem;
   /* background-color: red; */
}

.newBreadCrumb:first-child {
   margin-left: 0;
}

.backButton {
   outline: none;
   border: none;
   padding: 0;
   margin: 0;
   background-color: #12214a;
   border-radius: 5px;
   margin-bottom: 0.5rem;
}

.backButton:hover {
   background-color: #152655ca;
}

.backButtonIcon {
   height: 30px;
   width: 30px;
}
.toggle {
   background-color: gray !important;
}

.toggle:hover {
   background-color: gray !important;
}

/* Loading Area  */

.loadingComponent {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.loader {
   top: 50%;
   left: 50%;
   position: absolute;
   padding: 3rem;
   border-radius: 5px;
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   transform: translate(-50%, -50%);
}

.loaderSpin {
   margin: auto;
   width: 100%;
}

.loadingDescription {
   margin-top: 2rem;
   font-size: 0.8rem;
}
