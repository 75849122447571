.notFound {
   background-color: #f4f7ff;
   width: 100vw;
   height: 100vh;
}

.notFound > h1 {
   width: 20%;
   font-size: 1.3rem;
   text-align: center;
   line-height: 150%;
   background-color: #ebecfc;
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
   padding: 3rem;
   border-radius: 10px;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
