.blur {
   position: absolute;
   left: 0;
   top: 0;
   width: calc(100vw - 80px);
   min-height: 100vh;
   height: 100%;
   margin-left: 5rem;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 5 !important;
}
.none {
   /* display: none; */
   background-color: rgba(0, 0, 0, 0.4);
   visibility: hidden;
}

.topWrapper {
   display: flex;
   justify-content: space-between;
   /* margin-right: 2rem; */
}

.brandWrapper {
   background-color: white;
   display: flex;
   justify-content: space-between;
}
.brandName {
   font-size: 1rem;
   font-weight: 500;

   color: rgb(83, 83, 83);
   background-color: white;
   margin: 0;
   display: inline-block;
   padding: 0.6rem 2rem;
}

.brandName > span {
   color: #12214a;
   font-weight: 600;
}

.horizontalLine {
   width: 100%;
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.notificationWrapper {
   position: relative;
   /* background-color: blue; */
   display: inline-block;
   margin-top: 0.2rem;
   margin-right: 1rem;
}

.notificationTick {
   height: 8px;
   width: 8px;
   position: absolute;
   top: -2px;
   left: 14px;
   background-color: red;
   border-radius: 50%;
   animation: notificationAnimation 1s 10s infinite;
}

@keyframes notificationAnimation {
   0% {
      width: 8px;
      height: 8px;
   }
   50% {
      width: 9px;
      height: 9px;
   }
   100% {
      width: 8px;
      height: 8px;
   }
}

.headerWrapper {
   padding: 0 2rem;
}

.dashboardHeading {
   margin-top: 1rem;
   font-size: 1.8rem;
   /* color: rgb(75, 75, 75); */
   font-size: 1.3rem;
   font-weight: 900;
   color: #12214a;
   display: inline-block;
}

.dashboardGreeting {
   /* margin-left: 0.5rem; */
   display: inline-block;

   margin-left: 0.3rem;
   font-size: 1.1rem;
   font-weight: 400;
   color: rgb(119, 119, 119);
   letter-spacing: 1.2px;
   margin-bottom: 1rem;
}

.payNowContainer {
   width: auto;
   height: 210px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   box-shadow: 2px 3px 8px 1px #dfdfdf;
}

.backgroundImage {
   height: 200px;
   transform: rotate(20deg);
   top: 105px;
   left: -30px;
   position: absolute;
}

.visaLogo {
   position: absolute;
   height: 40px;
   bottom: 1rem;
   left: 1rem;
}

.cardHeading {
   font-weight: 900;
   margin: 0;

   font-size: 1rem;
   color: #12214a;
}

.cardPaymentWrap {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: stretch;
}

.amount {
   font-size: 1.4rem;
   font-weight: 400;
   color: #25345f;
}

.amount > span {
   font-size: 1rem;
   font-weight: 600;
}

.cardDate {
   font-size: 1rem;
   font-weight: 400;
   text-align: right;
   color: rgb(129, 129, 129);
}

.paynowWrap {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.paynow {
   background-color: #25345f;
   color: white;
   border: none;
   outline: none;
   padding: 0.5rem 0.8rem;
   border-radius: 10px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.paynow > p {
   display: inline-block;
   margin: 0;
   font-weight: 600;
   font-size: 0.7rem;
   margin-right: 0.5rem;
}

.paynow:hover {
   cursor: pointer;
   background-color: #293966;
   outline: none;
}

.paynow:active {
   outline: none;
   background-color: #293966;
}
.paynow:focus {
   outline: none;
   background-color: #293966;
}

/**
* Payment paymentHistory
**/

.paymentHistory {
   width: auto;

   height: 200px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   /* box-shadow: 2px 3px 8px 1px #dfdfdf; */
   position: relative;
}

.backgroundImagePaymentHistory {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.backgroundImagePaymentHistory2 {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   transform: rotate(140deg);
}

.cardDescription {
   font-weight: 500;
   font-size: 0.8rem;
   color: rgb(138, 137, 137);
   line-height: 1.5;
   margin-top: 1rem;
}

.detailsButtonWrapper {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
}

.backgroundImageInvoiceHistory {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.backgroundImageInvoiceHistory2 {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   transform: rotate(60deg);
}

@media (max-width: 406px) {
   .cardHeading {
      font-weight: 500;
      margin: 0;
      font-size: 0.9rem;
   }
   .amount {
      font-size: 1rem;
      font-weight: 700;
   }
   .cardDescription {
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 1.2;
   }
}

@media (max-width: 990px) {
   .home {
      overflow-y: hidden !important;
   }
   .scroller {
      height: 80vh;
      overflow-y: scroll;
      scrollbar-color: rebeccapurple green;
      scrollbar-width: thin;
   }
}

@media (min-with: 1200) {
}

/*Payment*/
.payment {
   border: 2px solid green;
   /* height: 280px; */
   border-radius: 20px;
   padding: 2rem;
   background: #fff;
   /* background-color: red; */
}
.paymentError {
   border: 2px solid red;
   /* height: 280px; */
   border-radius: 20px;
   padding: 2rem;
   background: #fff;
   /* background-color: red; */
}

.payment_header {
   /* background: rgb(42, 41, 41); */

   background-color: white;
   padding-bottom: 20px;
   border-radius: 20px 20px 0px 0px;
}

.check {
   margin: 0px auto;
   width: 5rem;
   height: 5rem;
   border-radius: 100%;
   background: #fff;
   text-align: center;
   background-color: green;
   color: white;
   position: relative;
}
.close {
   margin: 0px auto;
   width: 5rem;
   height: 5rem;
   border-radius: 100%;
   background: #fff;
   text-align: center;
   background-color: red;
   color: white;
   position: relative;
}

.checkicon {
   /* vertical-align: middle;
   height: 50px;
   display: flex;
   margin: 0 auto; */
   height: 3rem;
   width: 3rem;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: white;
}

.content {
   text-align: center;
}

.content h1 {
   font-size: 3rem;
   text-transform: uppercase;
   /* padding-top */
}

/* .content a {

} */

.content > div > p {
   font-size: 1.1rem;
   margin: 1rem 0;
}
.content a:hover {
   text-decoration: none;
   background: #000;
}

.goBackLink,
.goBackLink:hover {
   color: white !important;
   display: inline-block;
   padding: 0.5rem 1rem !important;
   margin-top: 1rem !important;
   font-size: 1.1rem;
   background-color: #12214a;

   /* width: 200px; */
   /* height: 35px; */
   /* color: #fff; */
   border-radius: 30px;
   /* padding: 5px 10px; */
   /* background: rgb(63, 63, 62); */
   transition: all ease-in-out 0.3s;
}
/*Invoice */

.invoicetable {
   padding: 0.1rem 0.1rem !important;
}
.invoiceTablehead {
   color: #566a7f;
   vertical-align: middle;
   text-transform: uppercase;
   font-size: 0.75rem;
   letter-spacing: 1px;
}
/*
Invoice Preview
*/
.docPage {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   /* padding-top: 1.8rem; */
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
}

.folderContainer {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.folderContainer > h4 {
   font-size: 1.2rem;
}
.fileStructurePreview {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.breadcrumbText {
   color: black !important;
   padding-right: 5px;
}
a:hover {
   color: #12214a !important;
}

.totalAmount {
   font-weight: 700;
   text-align: right;
}
.topdiv {
   flex-direction: column;
}
.label1 {
   margin-bottom: 0;
   font-size: 12px;
   padding-top: -5px;
}

.showAmount {
   font-size: 26px;
   text-transform: uppercase;
}
.amountText {
   color: #696969;
   font-weight: 400;
   padding-right: 10px;
}
.termsandConditions {
   color: blue !important;
   text-decoration: underline !important;
}
