.topWrapper {
   display: flex;
   justify-content: space-between;
   /* margin-right: 2rem; */
}

.brandWrapper {
   background-color: white;
   padding: 5px 0;
   display: flex;
   justify-content: space-between;
}
.brandName {
   font-size: 1rem;
   font-weight: 500;

   color: rgb(83, 83, 83);
   background-color: white;
   margin: 0;
   display: inline-block;
   padding: 0.6rem 2rem;
}

.brandName > span {
   color: #12214a;
   font-weight: 600;
}

.horizontalLine {
   width: 100%;
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.companyName {
   color: #12214a;
   font-weight: 2rem;
   letter-spacing: 0.8px;
   border-radius: 10px;
   border: #12214a 2px solid;
   padding: 0.3rem 1rem;
   margin-top: 1rem;
}
.notificationWrapper {
   position: relative;
   /* background-color: blue; */
   display: inline-block;
   margin-top: 0.2rem;
   margin-right: 1rem;
}

.notificationTick {
   height: 8px;
   width: 8px;
   position: absolute;
   top: -2px;
   left: 14px;
   background-color: red;
   border-radius: 50%;
   animation: notificationAnimation 1s 10s infinite;
}

@keyframes notificationAnimation {
   0% {
      width: 8px;
      height: 8px;
   }
   50% {
      width: 9px;
      height: 9px;
   }
   100% {
      width: 8px;
      height: 8px;
   }
}

.headerWrapper {
   padding: 0 2rem;
}

.dashboardHeading {
   margin-top: 1rem;
   font-size: 1.8rem;
   /* color: rgb(75, 75, 75); */
   font-size: 1.3rem;
   font-weight: 400;
   color: #12214a;
   display: inline-block;
}

.dashboardHeading > span {
   /* font-weight: 300; */
}

.dashboardGreeting {
   /* margin-left: 0.5rem; */
   display: inline-block;

   margin-left: 0.3rem;
   font-size: 1.1rem;
   font-weight: 400;
   color: rgb(119, 119, 119);
   letter-spacing: 1.2px;
   margin-bottom: 1rem;
}
.invoiceTBody {
   font-size: 12px;
   font-family: cursive;
}
.invoiceTBody > tr > td {
   font-size: 12px;
   font-family: cursive;
}
.notificationContent {
   display: flex;
}

.logout {
   padding-right: 10px;
   padding-left: 10px;
   /* border-left: 1px solid #bababa; */
}

.logout:hover {
   cursor: pointer;
}

.logoutIcon {
   padding-top: 5px;
}
