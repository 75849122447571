.sideBar {
   min-height: 100vh;
   /* width: 20vw; */
   background-color: #ebecfc;
   padding-top: 2rem;
   display: inline-block;
   position: fixed;
   z-index: 100;
   width: 80px;
   top: 0;
   left: 0;
   transition: all 1s;
}

.sideBar > .menuButton {
   text-align: left;
   display: flex;
}

.menuButton {
   margin-bottom: 2rem;
   margin-left: 1.5rem;
   display: flex;
   justify-content: space-between;
   margin-right: 2.5rem;
}

.brand {
   color: #0c1632;
   display: grid;
   grid-template-columns: max-content max-content;
   align-items: flex-end;
   margin-bottom: 0.5rem;
   transition: 2s;
   cursor: pointer;
   font-size: 1.2rem;
   overflow: hidden;
   margin-left: 1rem;
   font-weight: 700;
}

.brandHide {
   display: hidden;
}

.menuItems {
   height: 85vh !important;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.menuItemLink {
   color: #0c1632;
   text-decoration: none;
}

.menuItemLink:hover {
   color: #122351;
}

.menuItem {
   cursor: pointer;
}

.menuItemWrapper {
   display: grid;
   grid-template-columns: max-content max-content;
   align-items: center;
   column-gap: 1rem;
   border-radius: 0.5rem;
   margin-bottom: 0.5rem;
   transition: 0.3s;
   overflow: hidden;
   margin-left: 1.6rem;
}

.activeTick {
   display: inline-block;
   position: absolute;
   content: '';
   height: 1.5rem;
   background-color: #0c1632;
   width: 0.6rem;
   border-radius: 0 5px 5px 0;
}

.menuItem {
   margin: 1rem 0;
}

.menuItemLabel {
   margin-left: 1rem;
   font-size: 1rem;
   font-weight: 600;
   transition: all 0.8s;
}

.horizontalRule {
   height: 3px;
   margin: 1.5rem 1rem;
   background-color: #0c1632;
   border-radius: 10px;
}

/**
* Utility Classes
**/
.hidden {
   display: none;
}
.open {
   width: 220px;
   overflow-y: hidden;
}

.icons {
   color: #0c1632;
   cursor: pointer;
}

/* @media (min-width: 415px) {
   .open {
      width: 220px;
      overflow-y: hidden;
   }
} */
