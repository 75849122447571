.home {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   /* padding-top: 1.8rem; */
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
   /* padding-top: 2rem; */
   /* padding-left: 1rem;
   padding-right: 1rem; */
}

.blur {
   position: absolute;
   left: 0;
   top: 0;
   width: calc(100vw - 80px);
   min-height: 100vh;
   height: 100%;
   margin-left: 5rem;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 5 !important;
}
.none {
   /* display: none; */
   background-color: rgba(0, 0, 0, 0.4);
   visibility: hidden;
}
.payNowContainer {
   width: auto;
   height: 210px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   box-shadow: 2px 3px 8px 1px #dfdfdf;
}
.outStandingContainer {
   width: auto;
   height: auto;
   overflow: hidden;
   background-color: #25345f;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   flex-direction: row;
   box-shadow: 1px 2px 3px 1px #726e6e;
}

.backgroundImage {
   height: 200px;
   transform: rotate(20deg);
   top: 105px;
   left: -30px;
   position: absolute;
}

.cardHeading {
   font-weight: 900;
   margin: 0;

   font-size: 1rem;
   color: #12214a;
}

.cardPaymentWrap {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: stretch;
}

.amount > span {
   font-size: 1rem;
   font-weight: 600;
}

.paynowWrap {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.paynowLastChild {
   background-color: #2cd22c;
   color: white;
   border: none;
   outline: none;
   padding: 0.5rem 0.8rem;
   border-radius: 10px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.paynowLastChild:hover {
   background-color: #23ad23 !important;
   color: #293966 !important;
}

.paynowLastChild > p {
   font-size: 1rem !important;
   font-weight: 900;
}

.payNowLink {
   text-decoration: none;
   color: white;
   display: inline;
}

.payNowLink > .paynow {
   width: 100%;
}

.paynow > p {
   display: inline-block;
   margin: 0;
   font-weight: 600;
   font-size: 0.8rem;
   margin-right: 0.5rem;
}

.paynow:hover,
.payNowLink:hover {
   cursor: pointer;
   background-color: #293966;
   border-radius: 10px;
   outline: none;
   text-decoration: none;
   color: white;
}

.paynow:active {
   outline: none;
   background-color: #293966;
}
.paynow:focus {
   outline: none;
   background-color: #293966;
}

/**
* Payment paymentHistory
**/

.paymentHistory {
   width: auto;

   height: 200px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   /* box-shadow: 2px 3px 8px 1px #dfdfdf; */
   position: relative;
}

.backgroundImagePaymentHistory {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.backgroundImagePaymentHistory2 {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   transform: rotate(140deg);
}

.cardDescription {
   font-weight: 500;
   font-size: 0.8rem;
   color: rgb(138, 137, 137);
   line-height: 1.5;
   margin-top: 1rem;
}

.detailsButtonWrapper {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
}

.backgroundImageInvoiceHistory {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.backgroundImageInvoiceHistory2 {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   transform: rotate(60deg);
}

/* .open {  
   width: 220px;
} */

/* @media (max-width: 415px) {
    .home {
      width: calc(100vw - 80px);
      padding-top: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
   } 

   .open {
      width: 220px;
   }
} */

@media (max-width: 406px) {
   .cardHeading {
      font-weight: 500;
      margin: 0;
      font-size: 0.9rem;
   }
   .amount {
      font-size: 1rem;
      font-weight: 700;
   }
   .cardDescription {
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 1.2;
   }
}

@media (max-width: 990px) {
   .home {
      overflow-y: hidden !important;
   }
   .scroller {
      /* height: 80vh; */
      overflow-y: scroll;
      scrollbar-color: rebeccapurple green;
      scrollbar-width: thin;
   }
}

@media (min-with: 1200) {
}

/*OutStanding Payment card*/
.outStandingHeading {
   font-size: 25px;
   text-transform: uppercase;
   font-weight: 500;
   /* text-align: center; */
   width: 100%;
   color: white;
}
.outStandingHr {
   color: #e7e7e7;
}
.amount {
   color: #fff;
   /* text-align: center; */
   font-size: 29px;
   font-weight: 500;
}
.paynow {
   background-color: #25345f;
   color: #fbfcff;
   border: none;
   outline: none;
   padding: 0.5rem 0.8rem;
   border-radius: 10px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
   float: right;
}
.paynow1 {
   background-color: #fbfcff;
   color: #25345f;
   border: none;
   outline: none;
   padding: 0.5rem 0.8rem;
   border-radius: 10px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
   float: right;
   font-size: 15px;
   border: 1px solid black;
   font-weight: 500;
}
.cardDate {
   font-size: 1rem;
   font-weight: 400;
   text-align: right;
   color: #e7e7e7;
   margin-top: 12px;
}
.creaditCard {
   padding-left: 5px;
   margin-left: 10px;
}
.visaLogo {
   height: 25px;
   bottom: 1rem;
   left: 1rem;
   padding-right: 3px;
}

.loadingComponent {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.loader {
   top: 50%;
   left: 50%;
   position: absolute;
   padding: 3rem;
   border-radius: 5px;
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   transform: translate(-50%, -50%);
}

.loaderSpin {
   margin: auto;
   width: 100%;
}

.loadingDescription {
   margin-top: 2rem;
   font-size: 0.8rem;
}
