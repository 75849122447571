@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.Sidebar_sideBar__3_c3Z {
   min-height: 100vh;
   /* width: 20vw; */
   background-color: #ebecfc;
   padding-top: 2rem;
   display: inline-block;
   position: fixed;
   z-index: 100;
   width: 80px;
   top: 0;
   left: 0;
   transition: all 1s;
}

.Sidebar_sideBar__3_c3Z > .Sidebar_menuButton__3RrI2 {
   text-align: left;
   display: flex;
}

.Sidebar_menuButton__3RrI2 {
   margin-bottom: 2rem;
   margin-left: 1.5rem;
   display: flex;
   justify-content: space-between;
   margin-right: 2.5rem;
}

.Sidebar_brand__3nJNJ {
   color: #0c1632;
   display: grid;
   grid-template-columns: -webkit-max-content -webkit-max-content;
   grid-template-columns: max-content max-content;
   align-items: flex-end;
   margin-bottom: 0.5rem;
   transition: 2s;
   cursor: pointer;
   font-size: 1.2rem;
   overflow: hidden;
   margin-left: 1rem;
   font-weight: 700;
}

.Sidebar_brandHide__35Aew {
   display: hidden;
}

.Sidebar_menuItems__3ONPL {
   height: 85vh !important;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.Sidebar_menuItemLink__2sS3o {
   color: #0c1632;
   text-decoration: none;
}

.Sidebar_menuItemLink__2sS3o:hover {
   color: #122351;
}

.Sidebar_menuItem__Fr2yR {
   cursor: pointer;
}

.Sidebar_menuItemWrapper__1Z0UP {
   display: grid;
   grid-template-columns: -webkit-max-content -webkit-max-content;
   grid-template-columns: max-content max-content;
   align-items: center;
   grid-column-gap: 1rem;
   -webkit-column-gap: 1rem;
           column-gap: 1rem;
   border-radius: 0.5rem;
   margin-bottom: 0.5rem;
   transition: 0.3s;
   overflow: hidden;
   margin-left: 1.6rem;
}

.Sidebar_activeTick__20Vzx {
   display: inline-block;
   position: absolute;
   content: '';
   height: 1.5rem;
   background-color: #0c1632;
   width: 0.6rem;
   border-radius: 0 5px 5px 0;
}

.Sidebar_menuItem__Fr2yR {
   margin: 1rem 0;
}

.Sidebar_menuItemLabel__23bDy {
   margin-left: 1rem;
   font-size: 1rem;
   font-weight: 600;
   transition: all 0.8s;
}

.Sidebar_horizontalRule__3R-qv {
   height: 3px;
   margin: 1.5rem 1rem;
   background-color: #0c1632;
   border-radius: 10px;
}

/**
* Utility Classes
**/
.Sidebar_hidden__3RJZL {
   display: none;
}
.Sidebar_open__CvaHp {
   width: 220px;
   overflow-y: hidden;
}

.Sidebar_icons__6RoT- {
   color: #0c1632;
   cursor: pointer;
}

/* @media (min-width: 415px) {
   .open {
      width: 220px;
      overflow-y: hidden;
   }
} */

.companydrop {
   margin-top: 10px;
}
.companydrop-btn {
   /* border-radius: 0px !important; */
   background-color: #f4f7ff !important;
   color: #25345f !important;
   font-weight: 500 !important;
   border: 2px solid !important;
}
.dropdown-company {
   border: 1px solid !important;
}
.company-nav-item {
   font-size: 14px;
   color: black;
}
.company-nav-item:active {
   font-size: 14px;
   color: black;
   background-color: darkgray !important;
}

.TopNav_topWrapper__2v9A0 {
   display: flex;
   justify-content: space-between;
   /* margin-right: 2rem; */
}

.TopNav_brandWrapper__2C27X {
   background-color: white;
   padding: 5px 0;
   display: flex;
   justify-content: space-between;
}
.TopNav_brandName__2laUJ {
   font-size: 1rem;
   font-weight: 500;

   color: rgb(83, 83, 83);
   background-color: white;
   margin: 0;
   display: inline-block;
   padding: 0.6rem 2rem;
}

.TopNav_brandName__2laUJ > span {
   color: #12214a;
   font-weight: 600;
}

.TopNav_horizontalLine__1eQY6 {
   width: 100%;
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.TopNav_companyName__1w9zK {
   color: #12214a;
   font-weight: 2rem;
   letter-spacing: 0.8px;
   border-radius: 10px;
   border: #12214a 2px solid;
   padding: 0.3rem 1rem;
   margin-top: 1rem;
}
.TopNav_notificationWrapper__1vejN {
   position: relative;
   /* background-color: blue; */
   display: inline-block;
   margin-top: 0.2rem;
   margin-right: 1rem;
}

.TopNav_notificationTick__2p_sL {
   height: 8px;
   width: 8px;
   position: absolute;
   top: -2px;
   left: 14px;
   background-color: red;
   border-radius: 50%;
   -webkit-animation: TopNav_notificationAnimation__28W1R 1s 10s infinite;
           animation: TopNav_notificationAnimation__28W1R 1s 10s infinite;
}

@-webkit-keyframes TopNav_notificationAnimation__28W1R {
   0% {
      width: 8px;
      height: 8px;
   }
   50% {
      width: 9px;
      height: 9px;
   }
   100% {
      width: 8px;
      height: 8px;
   }
}

@keyframes TopNav_notificationAnimation__28W1R {
   0% {
      width: 8px;
      height: 8px;
   }
   50% {
      width: 9px;
      height: 9px;
   }
   100% {
      width: 8px;
      height: 8px;
   }
}

.TopNav_headerWrapper__1ZbSn {
   padding: 0 2rem;
}

.TopNav_dashboardHeading__3NWYE {
   margin-top: 1rem;
   font-size: 1.8rem;
   /* color: rgb(75, 75, 75); */
   font-size: 1.3rem;
   font-weight: 400;
   color: #12214a;
   display: inline-block;
}

.TopNav_dashboardHeading__3NWYE > span {
   /* font-weight: 300; */
}

.TopNav_dashboardGreeting__2T41s {
   /* margin-left: 0.5rem; */
   display: inline-block;

   margin-left: 0.3rem;
   font-size: 1.1rem;
   font-weight: 400;
   color: rgb(119, 119, 119);
   letter-spacing: 1.2px;
   margin-bottom: 1rem;
}
.TopNav_invoiceTBody__1DbLb {
   font-size: 12px;
   font-family: cursive;
}
.TopNav_invoiceTBody__1DbLb > tr > td {
   font-size: 12px;
   font-family: cursive;
}
.TopNav_notificationContent__3AM3H {
   display: flex;
}

.TopNav_logout__1g40Q {
   padding-right: 10px;
   padding-left: 10px;
   /* border-left: 1px solid #bababa; */
}

.TopNav_logout__1g40Q:hover {
   cursor: pointer;
}

.TopNav_logoutIcon__2qSBU {
   padding-top: 5px;
}

.ContactList_docPage___3hxv {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
}

.ContactList_folderContainer__2zbp5 {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.ContactList_folderContainer__2zbp5 > h4 {
   font-size: 1.2rem;
}

.ContactList_cardPreview__2FLPP {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   background-color: white;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.ContactList_breadcrumbText__15CLl {
   color: black !important;
   padding-right: 5px;
}
a:hover {
   color: #12214a !important;
}
/* .contacycard {
   margin-bottom: 24px;
   box-shadow: 0 2px 3px #e4e8f0;
} */
.ContactList_contactcard__q10Sn {
   border: 2px solid #293966 !important;
   margin-bottom: 11px !important;
}
.ContactList_avatarMd__2lmEr {
   height: 4rem;
   width: 4rem;
}
.ContactList_rounded-circle__1RCqo {
   border-radius: 50% !important;
}
.ContactList_img-thumbnail__2-62F {
   padding: 0.25rem;
   background-color: #f1f3f7;
   border: 1px solid #eff0f2;
   border-radius: 0.75rem;
}
.ContactList_avatar-title__2L9Mp {
   align-items: center;
   background-color: #3b76e1;
   color: #fff;
   display: flex;
   font-weight: 500;
   height: 100%;
   justify-content: center;
   width: 100%;
}
.ContactList_bg-soft-primary__3r9fj {
   background-color: rgba(59, 118, 225, 0.25) !important;
}
a {
   text-decoration: none !important;
}
.ContactList_badge-soft-danger__3KpNm {
   color: #f56e6e !important;
   background-color: rgba(245, 110, 110, 0.1);
}
.ContactList_badge-soft-success__3COP2 {
   color: #63ad6f !important;
   background-color: rgba(99, 173, 111, 0.1);
}
.ContactList_mb-0__3EHM5 {
   margin-bottom: 0 !important;
}
.ContactList_badge__3a04J {
   display: inline-block;
   padding: 0.25em 0.6em;
   font-size: 75%;
   font-weight: 500;
   line-height: 1;
   color: #fff;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: 0.75rem;
}
.ContactList_contactName__2nk_Q {
   font-size: 18px;
   /* padding-top: 27px; */
   margin-top: 9px;
   font-weight: 400;
   color: black;
   text-transform: uppercase;
   padding-bottom: 0px;
   margin-bottom: 0px;
}
.ContactList_title__BceHk {
   font-size: 14px;
   color: darkslategrey;
}
.ContactList_contactDetail__Xl4CM {
   color: #293966;
   font-size: 13px;
   letter-spacing: 0.4px;
}

.Docs_docPage__jhf9n {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 80px);
   margin-left: 80px;
}

.Docs_folderContainer__2ApXB {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.Docs_folderContainer__2ApXB > h4 {
   font-size: 1.2rem;
}

.Docs_fileStructurePreview__3HwRZ {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.Docs_folderComponentList__c0aG1 {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}

.Docs_folderComponent__2uOPz {
   width: 150px;
   display: flex;
   flex-direction: column;
   margin-right: 1rem;
   justify-content: center;
   transition: 0.3s;
}

.Docs_folderComponent__2uOPz:hover {
   cursor: pointer;
   background-color: rgb(229, 229, 229);
}

.Docs_folderIcon__1QzxX {
   height: 100px;
}

.Docs_folderDescription__3Ku7V {
   max-width: 250px;
   font-size: 0.8rem;
   display: inline-block;
   text-align: center;
}

.Docs_newBreadCrumb__3pixA {
   display: inline-block;
   font-size: 0.8rem;
   color: rgb(98, 97, 97);
   margin: 0 1rem;
   /* background-color: red; */
}

.Docs_newBreadCrumb__3pixA:first-child {
   margin-left: 0;
}

.Docs_backButton__3QIAm {
   outline: none;
   border: none;
   padding: 0;
   margin: 0;
   background-color: #12214a;
   border-radius: 5px;
   margin-bottom: 0.5rem;
}

.Docs_backButton__3QIAm:hover {
   background-color: #152655ca;
}

.Docs_backButtonIcon__1Gg5i {
   height: 30px;
   width: 30px;
}
.Docs_toggle__1SaJM {
   background-color: gray !important;
}

.Docs_toggle__1SaJM:hover {
   background-color: gray !important;
}

/* Loading Area  */

.Docs_loadingComponent__2gWwZ {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.Docs_loader__3cDso {
   top: 50%;
   left: 50%;
   position: absolute;
   padding: 3rem;
   border-radius: 5px;
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
}

.Docs_loaderSpin__oTDuO {
   margin: auto;
   width: 100%;
}

.Docs_loadingDescription__3VugP {
   margin-top: 2rem;
   font-size: 0.8rem;
}

.Card_card__J7Xl9 {
   box-shadow: 2px 3px 8px 1px #dfdfdf;
   width: auto;
   height: 210px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   position: relative;
}

.Home_home__1rSb3 {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   /* padding-top: 1.8rem; */
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
   /* padding-top: 2rem; */
   /* padding-left: 1rem;
   padding-right: 1rem; */
}

.Home_blur__tmWfn {
   position: absolute;
   left: 0;
   top: 0;
   width: calc(100vw - 80px);
   min-height: 100vh;
   height: 100%;
   margin-left: 5rem;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 5 !important;
}
.Home_none__3yyB8 {
   /* display: none; */
   background-color: rgba(0, 0, 0, 0.4);
   visibility: hidden;
}
.Home_payNowContainer__2BOs5 {
   width: auto;
   height: 210px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   box-shadow: 2px 3px 8px 1px #dfdfdf;
}
.Home_outStandingContainer__2HPHw {
   width: auto;
   height: auto;
   overflow: hidden;
   background-color: #25345f;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   flex-direction: row;
   box-shadow: 1px 2px 3px 1px #726e6e;
}

.Home_backgroundImage__1Bgjr {
   height: 200px;
   -webkit-transform: rotate(20deg);
           transform: rotate(20deg);
   top: 105px;
   left: -30px;
   position: absolute;
}

.Home_cardHeading__2yRQI {
   font-weight: 900;
   margin: 0;

   font-size: 1rem;
   color: #12214a;
}

.Home_cardPaymentWrap__1EpJ9 {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: stretch;
}

.Home_amount__2g7XD > span {
   font-size: 1rem;
   font-weight: 600;
}

.Home_paynowWrap__1q6dK {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.Home_paynowLastChild__1abbp {
   background-color: #2cd22c;
   color: white;
   border: none;
   outline: none;
   padding: 0.5rem 0.8rem;
   border-radius: 10px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.Home_paynowLastChild__1abbp:hover {
   background-color: #23ad23 !important;
   color: #293966 !important;
}

.Home_paynowLastChild__1abbp > p {
   font-size: 1rem !important;
   font-weight: 900;
}

.Home_payNowLink__101tT {
   text-decoration: none;
   color: white;
   display: inline;
}

.Home_payNowLink__101tT > .Home_paynow__A9w0h {
   width: 100%;
}

.Home_paynow__A9w0h > p {
   display: inline-block;
   margin: 0;
   font-weight: 600;
   font-size: 0.8rem;
   margin-right: 0.5rem;
}

.Home_paynow__A9w0h:hover,
.Home_payNowLink__101tT:hover {
   cursor: pointer;
   background-color: #293966;
   border-radius: 10px;
   outline: none;
   text-decoration: none;
   color: white;
}

.Home_paynow__A9w0h:active {
   outline: none;
   background-color: #293966;
}
.Home_paynow__A9w0h:focus {
   outline: none;
   background-color: #293966;
}

/**
* Payment paymentHistory
**/

.Home_paymentHistory__3Zi2_ {
   width: auto;

   height: 200px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   /* box-shadow: 2px 3px 8px 1px #dfdfdf; */
   position: relative;
}

.Home_backgroundImagePaymentHistory__1QJoR {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.Home_backgroundImagePaymentHistory2__2Y__C {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   -webkit-transform: rotate(140deg);
           transform: rotate(140deg);
}

.Home_cardDescription__2UKDz {
   font-weight: 500;
   font-size: 0.8rem;
   color: rgb(138, 137, 137);
   line-height: 1.5;
   margin-top: 1rem;
}

.Home_detailsButtonWrapper__1eFyP {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
}

.Home_backgroundImageInvoiceHistory__Mqt47 {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.Home_backgroundImageInvoiceHistory2__28-kt {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   -webkit-transform: rotate(60deg);
           transform: rotate(60deg);
}

/* .open {  
   width: 220px;
} */

/* @media (max-width: 415px) {
    .home {
      width: calc(100vw - 80px);
      padding-top: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
   } 

   .open {
      width: 220px;
   }
} */

@media (max-width: 406px) {
   .Home_cardHeading__2yRQI {
      font-weight: 500;
      margin: 0;
      font-size: 0.9rem;
   }
   .Home_amount__2g7XD {
      font-size: 1rem;
      font-weight: 700;
   }
   .Home_cardDescription__2UKDz {
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 1.2;
   }
}

@media (max-width: 990px) {
   .Home_home__1rSb3 {
      overflow-y: hidden !important;
   }
   .Home_scroller__728Xe {
      /* height: 80vh; */
      overflow-y: scroll;
      scrollbar-color: rebeccapurple green;
      scrollbar-width: thin;
   }
}

@media (min-with: 1200) {
}

/*OutStanding Payment card*/
.Home_outStandingHeading__3zWb0 {
   font-size: 25px;
   text-transform: uppercase;
   font-weight: 500;
   /* text-align: center; */
   width: 100%;
   color: white;
}
.Home_outStandingHr__Ng26g {
   color: #e7e7e7;
}
.Home_amount__2g7XD {
   color: #fff;
   /* text-align: center; */
   font-size: 29px;
   font-weight: 500;
}
.Home_paynow__A9w0h {
   background-color: #25345f;
   color: #fbfcff;
   border: none;
   outline: none;
   padding: 0.5rem 0.8rem;
   border-radius: 10px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
   float: right;
}
.Home_paynow1__1R7ab {
   background-color: #fbfcff;
   color: #25345f;
   border: none;
   outline: none;
   padding: 0.5rem 0.8rem;
   border-radius: 10px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
   float: right;
   font-size: 15px;
   border: 1px solid black;
   font-weight: 500;
}
.Home_cardDate__3CUKl {
   font-size: 1rem;
   font-weight: 400;
   text-align: right;
   color: #e7e7e7;
   margin-top: 12px;
}
.Home_creaditCard__91b4Y {
   padding-left: 5px;
   margin-left: 10px;
}
.Home_visaLogo__3XWHD {
   height: 25px;
   bottom: 1rem;
   left: 1rem;
   padding-right: 3px;
}

.Home_loadingComponent__wEOXT {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.Home_loader__qtreC {
   top: 50%;
   left: 50%;
   position: absolute;
   padding: 3rem;
   border-radius: 5px;
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
}

.Home_loaderSpin__1CNer {
   margin: auto;
   width: 100%;
}

.Home_loadingDescription__3SMEi {
   margin-top: 2rem;
   font-size: 0.8rem;
}

.Invoices_docPage__9pY03 {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
}

.Invoices_folderContainer__d6eNd {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.Invoices_folderContainer__d6eNd > h4 {
   font-size: 1.2rem;
}

.Invoices_fileStructurePreview__ur_qw {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.Invoices_invoicetable__2Y-jM {
   padding: 0.1rem 0.1rem !important;
}
.Invoices_invoiceTablehead__qQit9 {
   color: #566a7f;
   vertical-align: middle;
   text-transform: uppercase;
   font-size: 0.75rem;
   letter-spacing: 1px;
}

.Invoices_loadingComponent__3fOrN {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.Invoices_loader__-YQ8C {
   top: 50%;
   left: 50%;
   position: absolute;
   padding: 3rem;
   border-radius: 5px;
   background-color: white;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
}

.Invoices_loadingDescription__2bdLK {
   margin-top: 1rem;
}

/*Invoice Detail*/
.Invoices_invoiceLogo__2cxiD {
   width: 100px !important;
   height: 100px;
}
.Invoices_invoiceHeading__1Zm9S {
   margin-bottom: 15px;
}
.Invoices_invoiceAddress__6VU1Y {
   padding-bottom: 0px;
   margin-bottom: 0px;
   font-size: 14px;
}

.Invoices_invoiceCard__3vqYo {
   margin-bottom: 2px;
}
.Invoices_invoiceDate__mmcAj,
.Invoices_invoiceNumber__2m58q,
.Invoices_invoiceReference__1PiB6 {
   margin-bottom: 0px;
   font-weight: 500;
   font-size: 14px;
}
.Invoices_invoiceDateVal__1JzCk,
.Invoices_invoiceNumberVal__nDJEK,
.Invoices_invoiceReferenceVal__3p_vZ {
   margin-bottom: 1px;
   padding-left: 10px;
   font-size: 14px;
}
.Invoices_invoiceTable__2dbKN {
   font-size: 14px;
}
.Invoices_invoiceTable__2dbKN > thead > tr > th {
   font-weight: 500;
   font-size: 14px;
}
.Invoices_invoiceTable__2dbKN > tbody > tr > td {
   font-weight: 400;
   font-size: 14px;
}
.Invoices_tableTopBorder__1el1W {
   border-top: 1px solid black !important;
   border-top-width: 2px !important;
}
.Invoices_bedgeInvoice__3h0-y {
   margin-left: 1rem;
   /* height: 100%; */
   /* background: red !important; */
   vertical-align: middle !important;
}

.Invoices_docPage__2g8e_ {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
}

.Invoices_folderContainer__1DXOD {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.Invoices_folderContainer__1DXOD > h4 {
   font-size: 1.2rem;
}

.Invoices_fileStructurePreview__2wL8U {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.Invoices_invoicetable__1af5c {
   padding: 0.1rem 0.1rem !important;
}
.Invoices_invoiceTablehead__F-9DH {
   color: #566a7f;
   vertical-align: middle;
   text-transform: uppercase;
   font-size: 0.75rem;
   letter-spacing: 1px;
}

.Invoices_loadingComponent__2raZB {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.Invoices_loader__KevXz {
   top: 50%;
   left: 50%;
   position: absolute;
   padding: 3rem;
   border-radius: 5px;
   background-color: white;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
}

.Invoices_loadingDescription__23oLz {
   margin-top: 1rem;
}
.Invoices_breadcrumbText__3NOrr {
   color: black !important;
   padding-right: 5px;
}
a:hover {
   color: #12214a !important;
}

/* Sujeevan design implementation */
.Login_loginPage__NisyX {
   height: 100vh;
   width: 100vw;
   display: flex;
   background-color: #1a2c5d28;
   justify-content: center;
   align-items: center;
   font-family: 'Noto Sans', sans-serif;
}

.Login_loginContainer__2tbfT {
   background-color: aliceblue;
   border-radius: 10px;
   box-shadow: 3px 10px 9px -1px rgba(0, 0, 0, 0.1);
}

.Login_header__2-9SZ {
   background-color: #0b1431;
   padding-top: 2rem;
   padding-left: 3rem;
   padding-bottom: 1rem;
   color: white;
   border-radius: 10px 10px 0 0;
}

.Login_header__2-9SZ > h1 {
   font-size: 2rem;
}

.Login_lockIconWrapper__3LEG_ {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin-top: 1.5rem;
}

.Login_lockIcon__2kxvj {
   width: 30px;
   margin-bottom: 0.3rem;
}

.Login_iconCaption__1SyEw {
   font-size: 0.9rem;
}

.Login_inputSection__PhMNL {
   padding: 0 3rem;
   padding-top: 1rem;
   padding-bottom: 1.5rem;
   display: flex;
   justify-content: center;
   flex-direction: column;
}

.Login_inputSection__PhMNL > input {
   /* display: flex; */
   min-width: 300px;
   border: #0b1431a8 solid 2px;
   border-radius: 10px;
   padding: 0.6rem 1rem;
   outline: none;
   font-size: 0.8rem;
}

.Login_inputSection__PhMNL > input:focus {
   border: #0b1431 solid 2px;
}

.Login_inputSection__PhMNL > input:hover {
   border: #0b1431 solid 2px;
   cursor: pointer;
}

.Login_passwordInput__1HeZG {
   margin-top: 1rem;
}

.Login_errorText__3C5I4 {
   color: red;
   padding: 0 3rem;
   margin-bottom: 1rem;
   font-size: 0.8rem;
}

.Login_utilityContainer__TkYil {
   display: flex;
   padding: 0 3rem;
   padding-bottom: 1.2rem;
   justify-content: space-between;
}

.Login_rememberMe__1UVeH {
   cursor: pointer;
   font-size: 0.9rem;
   color: #454545;
}

.Login_checkBox__1Jvo5 {
   accent-color: #12214a;
   margin-right: 0.5rem;
}

.Login_forgotPasswordDiv__2kq3h > a {
   font-size: 0.9rem;
   color: rgb(161, 161, 161);
}

.Login_buttonContainer__2T3Jr {
   display: flex;
   justify-content: center;
}

.Login_buttonContainer__2T3Jr > button {
   border: none;
   background-color: #0b1431;
   color: white;
   padding: 0.4rem 1rem;
   border-radius: 10px;
   font-size: 1rem;
   margin-bottom: 1.5rem;
}

.Login_horizontalLine__3OTgi {
   margin: 0 3rem;
   height: 3px;
   /* width: 100%; */
   background-color: rgb(156, 154, 154);
   border-radius: 10px;
   margin-bottom: 1rem;
}

.Login_description__3HoZP {
   text-align: center;
   font-size: 0.8rem;
   padding: 1rem 3rem;
}

.Login_loadingComponent__YV0tL {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.Login_loader__1ZKZC {
   top: 50%;
   left: 50%;
   position: absolute;
   padding: 3rem;
   border-radius: 5px;
   background-color: white;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
}

.Login_loadingDescription__3p7gR {
   margin-top: 1rem;
}

.NotFound_notFound__2pquc {
   background-color: #f4f7ff;
   width: 100vw;
   height: 100vh;
}

.NotFound_notFound__2pquc > h1 {
   width: 20%;
   font-size: 1.3rem;
   text-align: center;
   line-height: 150%;
   background-color: #ebecfc;
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
   padding: 3rem;
   border-radius: 10px;
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
}

.PaymentDetail_blur__3TN5y {
   position: absolute;
   left: 0;
   top: 0;
   width: calc(100vw - 80px);
   min-height: 100vh;
   height: 100%;
   margin-left: 5rem;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 5 !important;
}
.PaymentDetail_none__3BMJB {
   /* display: none; */
   background-color: rgba(0, 0, 0, 0.4);
   visibility: hidden;
}

.PaymentDetail_topWrapper__1JxAH {
   display: flex;
   justify-content: space-between;
   /* margin-right: 2rem; */
}

.PaymentDetail_brandWrapper__3vJEV {
   background-color: white;
   display: flex;
   justify-content: space-between;
}
.PaymentDetail_brandName__f16ug {
   font-size: 1rem;
   font-weight: 500;

   color: rgb(83, 83, 83);
   background-color: white;
   margin: 0;
   display: inline-block;
   padding: 0.6rem 2rem;
}

.PaymentDetail_brandName__f16ug > span {
   color: #12214a;
   font-weight: 600;
}

.PaymentDetail_horizontalLine__YwD-L {
   width: 100%;
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.PaymentDetail_notificationWrapper__3428O {
   position: relative;
   /* background-color: blue; */
   display: inline-block;
   margin-top: 0.2rem;
   margin-right: 1rem;
}

.PaymentDetail_notificationTick__2kozF {
   height: 8px;
   width: 8px;
   position: absolute;
   top: -2px;
   left: 14px;
   background-color: red;
   border-radius: 50%;
   -webkit-animation: PaymentDetail_notificationAnimation__3IJgK 1s 10s infinite;
           animation: PaymentDetail_notificationAnimation__3IJgK 1s 10s infinite;
}

@-webkit-keyframes PaymentDetail_notificationAnimation__3IJgK {
   0% {
      width: 8px;
      height: 8px;
   }
   50% {
      width: 9px;
      height: 9px;
   }
   100% {
      width: 8px;
      height: 8px;
   }
}

@keyframes PaymentDetail_notificationAnimation__3IJgK {
   0% {
      width: 8px;
      height: 8px;
   }
   50% {
      width: 9px;
      height: 9px;
   }
   100% {
      width: 8px;
      height: 8px;
   }
}

.PaymentDetail_headerWrapper__2ztDD {
   padding: 0 2rem;
}

.PaymentDetail_dashboardHeading__9jr5R {
   margin-top: 1rem;
   font-size: 1.8rem;
   font-size: 1.3rem;
   font-weight: 900;
   color: #12214a;
   display: inline-block;
}

.PaymentDetail_dashboardGreeting__1WUHj {
   /* margin-left: 0.5rem; */
   display: inline-block;

   margin-left: 0.3rem;
   font-size: 1.1rem;
   font-weight: 400;
   color: rgb(119, 119, 119);
   letter-spacing: 1.2px;
   margin-bottom: 1rem;
}

.PaymentDetail_payNowContainer__qB5xf {
   width: auto;
   height: 210px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   box-shadow: 2px 3px 8px 1px #dfdfdf;
}

.PaymentDetail_backgroundImage__2Cowv {
   height: 200px;
   -webkit-transform: rotate(20deg);
           transform: rotate(20deg);
   top: 105px;
   left: -30px;
   position: absolute;
}

.PaymentDetail_visaLogo__28qnL {
   position: absolute;
   height: 40px;
   bottom: 1rem;
   left: 1rem;
}

.PaymentDetail_cardHeading__3-ewM {
   font-weight: 900;
   margin: 0;

   font-size: 1rem;
   color: #12214a;
}

.PaymentDetail_cardPaymentWrap__Vb-PH {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: stretch;
}

.PaymentDetail_amount__OJ8LN {
   font-size: 1.4rem;
   font-weight: 400;
   color: #25345f;
}

.PaymentDetail_amount__OJ8LN > span {
   font-size: 1rem;
   font-weight: 600;
}

.PaymentDetail_cardDate__13OgJ {
   font-size: 1rem;
   font-weight: 400;
   text-align: right;
   color: rgb(129, 129, 129);
}

.PaymentDetail_paynowWrap__3UHqR {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.PaymentDetail_paynow__3kSeo {
   background-color: #25345f;
   color: white;
   border: none;
   outline: none;
   padding: 0.5rem 0.8rem;
   border-radius: 10px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.PaymentDetail_paynow__3kSeo > p {
   display: inline-block;
   margin: 0;
   font-weight: 600;
   font-size: 0.7rem;
   margin-right: 0.5rem;
}

.PaymentDetail_paynow__3kSeo:hover {
   cursor: pointer;
   background-color: #293966;
   outline: none;
}

.PaymentDetail_paynow__3kSeo:active {
   outline: none;
   background-color: #293966;
}
.PaymentDetail_paynow__3kSeo:focus {
   outline: none;
   background-color: #293966;
}

/**
* Payment paymentHistory
**/

.PaymentDetail_paymentHistory__3i9JL {
   width: auto;

   height: 200px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   /* box-shadow: 2px 3px 8px 1px #dfdfdf; */
   position: relative;
}

.PaymentDetail_backgroundImagePaymentHistory__26Rg7 {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.PaymentDetail_backgroundImagePaymentHistory2__3U0NJ {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   -webkit-transform: rotate(140deg);
           transform: rotate(140deg);
}

.PaymentDetail_cardDescription__31daK {
   font-weight: 500;
   font-size: 0.8rem;
   color: rgb(138, 137, 137);
   line-height: 1.5;
   margin-top: 1rem;
}

.PaymentDetail_detailsButtonWrapper__3F0ej {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
}

.PaymentDetail_backgroundImageInvoiceHistory__1t5Nm {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.PaymentDetail_backgroundImageInvoiceHistory2__2qpQH {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   -webkit-transform: rotate(60deg);
           transform: rotate(60deg);
}

@media (max-width: 406px) {
   .PaymentDetail_cardHeading__3-ewM {
      font-weight: 500;
      margin: 0;
      font-size: 0.9rem;
   }
   .PaymentDetail_amount__OJ8LN {
      font-size: 1rem;
      font-weight: 700;
   }
   .PaymentDetail_cardDescription__31daK {
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 1.2;
   }
}

@media (max-width: 990px) {
   .PaymentDetail_home__bIJv5 {
      overflow-y: hidden !important;
   }
   .PaymentDetail_scroller__1LXNG {
      height: 80vh;
      overflow-y: scroll;
      scrollbar-color: rebeccapurple green;
      scrollbar-width: thin;
   }
}

@media (min-with: 1200) {
}

/*Payment*/
.PaymentDetail_payment__3_bJX {
   border: 2px solid green;
   /* height: 280px; */
   border-radius: 20px;
   padding: 2rem;
   background: #fff;
   /* background-color: red; */
}

.PaymentDetail_payment_header__21EVC {
   /* background: rgb(42, 41, 41); */

   background-color: white;
   padding-bottom: 20px;
   border-radius: 20px 20px 0px 0px;
}

.PaymentDetail_check__qKd2U {
   margin: 0px auto;
   width: 5rem;
   height: 5rem;
   border-radius: 100%;
   background: #fff;
   text-align: center;
   background-color: green;
   color: white;
   position: relative;
}

.PaymentDetail_checkicon__3bowo {
   /* vertical-align: middle;
   height: 50px;
   display: flex;
   margin: 0 auto; */
   height: 3rem;
   width: 3rem;
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
   color: white;
}

.PaymentDetail_content__12Buu {
   text-align: center;
}

.PaymentDetail_content__12Buu h1 {
   font-size: 3rem;
   text-transform: uppercase;
   /* padding-top */
}

/* .content a {

} */

.PaymentDetail_content__12Buu > div > p {
   font-size: 1.1rem;
   margin: 1rem 0;
}
.PaymentDetail_content__12Buu a:hover {
   text-decoration: none;
   background: #000;
}

.PaymentDetail_goBackLink__21_A8 {
   color: white !important;
   display: inline-block;
   padding: 0.5rem 1rem !important;
   margin-top: 1rem !important;
   font-size: 1.1rem;
   background-color: #12214a;

   /* width: 200px; */
   /* height: 35px; */
   /* color: #fff; */
   border-radius: 30px;
   /* padding: 5px 10px; */
   /* background: rgb(63, 63, 62); */
   transition: all ease-in-out 0.3s;
}

.PaymentDetail_goBackLink__21_A8:hover {
   background-color: #000;
   color: white !important;
}
/*Invoice */

.PaymentDetail_invoicetable__neUkf {
   padding: 0.1rem 0.1rem !important;
}
.PaymentDetail_invoiceTablehead__3GHuv {
   color: #566a7f;
   vertical-align: middle;
   text-transform: uppercase;
   font-size: 0.75rem;
   letter-spacing: 1px;
}
/*
Invoice Preview
*/
.PaymentDetail_docPage__2Eqa- {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   /* padding-top: 1.8rem; */
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
}

.PaymentDetail_folderContainer__3NEQJ {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.PaymentDetail_folderContainer__3NEQJ > h4 {
   font-size: 1.2rem;
}
.PaymentDetail_fileStructurePreview__zXkJG {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.PaymentDetail_breadcrumbText__36PT6 {
   color: black !important;
   padding-right: 5px;
}
a:hover {
   color: #12214a !important;
}

.PaymentDetail_totalAmount__Hr2TR {
   font-weight: 700;
   text-align: right;
}
.PaymentDetail_topdiv__wlHsW {
   flex-direction: column;
}
.PaymentDetail_label1__3-VZn {
   margin-bottom: 0;
   font-size: 12px;
   padding-top: -5px;
}

.PaymentDetail_showAmount__3rSYr {
   font-size: 26px;
   text-transform: uppercase;
}
.PaymentDetail_amountText__1Sa1N {
   color: #696969;
   font-weight: 400;
   padding-right: 10px;
}
.PaymentDetail_termsandConditions__30i5k {
   color: blue !important;
   text-decoration: underline !important;
}

.PaymentDetail_loadingComponent__2viet {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.PaymentDetail_policyContainer__2g8cv {
   position: absolute;
   top: 50%;
   left: 50%;
   background-color: white;
   padding: 3rem 3rem;
   border-radius: 10px;
   /* width: 80%; */
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
}

.PaymentDetail_policyContainer__2g8cv > h1 {
   font-size: 2rem;
   color: #12214a;
   margin-bottom: 1.5rem;
}

.PaymentDetail_policyContainer__2g8cv > ul {
   /* list-style: none; */
   color: rgb(63, 63, 63);
   margin-left: 1rem;
}

.PaymentDetail_buttonGoBack__3Xw4C {
   outline: none;
   border: #12214a 2px solid;
   color: #12214a;
   /* display: inline-block; */
   padding: 0.8rem 1rem;
   width: 100px;
   margin: auto;
   cursor: pointer;
   border-radius: 10px;
   margin-top: 1.5rem;
   transition: 0.3s all;
}

.PaymentDetail_buttonGoBack__3Xw4C:hover {
   color: white;
   background-color: #12214a;
}

.PaymentDetail_blur__2K2ed {
   position: absolute;
   left: 0;
   top: 0;
   width: calc(100vw - 80px);
   min-height: 100vh;
   height: 100%;
   margin-left: 5rem;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 5 !important;
}
.PaymentDetail_none__2VOtC {
   /* display: none; */
   background-color: rgba(0, 0, 0, 0.4);
   visibility: hidden;
}

.PaymentDetail_topWrapper__1Db8e {
   display: flex;
   justify-content: space-between;
   /* margin-right: 2rem; */
}

.PaymentDetail_brandWrapper__72-v_ {
   background-color: white;
   display: flex;
   justify-content: space-between;
}
.PaymentDetail_brandName__1Nhe5 {
   font-size: 1rem;
   font-weight: 500;

   color: rgb(83, 83, 83);
   background-color: white;
   margin: 0;
   display: inline-block;
   padding: 0.6rem 2rem;
}

.PaymentDetail_brandName__1Nhe5 > span {
   color: #12214a;
   font-weight: 600;
}

.PaymentDetail_horizontalLine__21BhN {
   width: 100%;
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.PaymentDetail_notificationWrapper__7E5ur {
   position: relative;
   /* background-color: blue; */
   display: inline-block;
   margin-top: 0.2rem;
   margin-right: 1rem;
}

.PaymentDetail_notificationTick__12hQK {
   height: 8px;
   width: 8px;
   position: absolute;
   top: -2px;
   left: 14px;
   background-color: red;
   border-radius: 50%;
   -webkit-animation: PaymentDetail_notificationAnimation__2s3Wa 1s 10s infinite;
           animation: PaymentDetail_notificationAnimation__2s3Wa 1s 10s infinite;
}

@-webkit-keyframes PaymentDetail_notificationAnimation__2s3Wa {
   0% {
      width: 8px;
      height: 8px;
   }
   50% {
      width: 9px;
      height: 9px;
   }
   100% {
      width: 8px;
      height: 8px;
   }
}

@keyframes PaymentDetail_notificationAnimation__2s3Wa {
   0% {
      width: 8px;
      height: 8px;
   }
   50% {
      width: 9px;
      height: 9px;
   }
   100% {
      width: 8px;
      height: 8px;
   }
}

.PaymentDetail_headerWrapper__2LxTt {
   padding: 0 2rem;
}

.PaymentDetail_dashboardHeading__gFUD9 {
   margin-top: 1rem;
   font-size: 1.8rem;
   /* color: rgb(75, 75, 75); */
   font-size: 1.3rem;
   font-weight: 900;
   color: #12214a;
   display: inline-block;
}

.PaymentDetail_dashboardGreeting__2CfQp {
   /* margin-left: 0.5rem; */
   display: inline-block;

   margin-left: 0.3rem;
   font-size: 1.1rem;
   font-weight: 400;
   color: rgb(119, 119, 119);
   letter-spacing: 1.2px;
   margin-bottom: 1rem;
}

.PaymentDetail_payNowContainer__1W874 {
   width: auto;
   height: 210px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   box-shadow: 2px 3px 8px 1px #dfdfdf;
}

.PaymentDetail_backgroundImage__yHpyI {
   height: 200px;
   -webkit-transform: rotate(20deg);
           transform: rotate(20deg);
   top: 105px;
   left: -30px;
   position: absolute;
}

.PaymentDetail_visaLogo__3eKA8 {
   position: absolute;
   height: 40px;
   bottom: 1rem;
   left: 1rem;
}

.PaymentDetail_cardHeading__3upQn {
   font-weight: 900;
   margin: 0;

   font-size: 1rem;
   color: #12214a;
}

.PaymentDetail_cardPaymentWrap__23-Iv {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: stretch;
}

.PaymentDetail_amount__3Mm95 {
   font-size: 1.4rem;
   font-weight: 400;
   color: #25345f;
}

.PaymentDetail_amount__3Mm95 > span {
   font-size: 1rem;
   font-weight: 600;
}

.PaymentDetail_cardDate__3IJz3 {
   font-size: 1rem;
   font-weight: 400;
   text-align: right;
   color: rgb(129, 129, 129);
}

.PaymentDetail_paynowWrap__lgK30 {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.PaymentDetail_paynow__2ICoy {
   background-color: #25345f;
   color: white;
   border: none;
   outline: none;
   padding: 0.5rem 0.8rem;
   border-radius: 10px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.PaymentDetail_paynow__2ICoy > p {
   display: inline-block;
   margin: 0;
   font-weight: 600;
   font-size: 0.7rem;
   margin-right: 0.5rem;
}

.PaymentDetail_paynow__2ICoy:hover {
   cursor: pointer;
   background-color: #293966;
   outline: none;
}

.PaymentDetail_paynow__2ICoy:active {
   outline: none;
   background-color: #293966;
}
.PaymentDetail_paynow__2ICoy:focus {
   outline: none;
   background-color: #293966;
}

/**
* Payment paymentHistory
**/

.PaymentDetail_paymentHistory__PEpjB {
   width: auto;

   height: 200px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   /* box-shadow: 2px 3px 8px 1px #dfdfdf; */
   position: relative;
}

.PaymentDetail_backgroundImagePaymentHistory__2e5jQ {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.PaymentDetail_backgroundImagePaymentHistory2__3MeBR {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   -webkit-transform: rotate(140deg);
           transform: rotate(140deg);
}

.PaymentDetail_cardDescription__450ly {
   font-weight: 500;
   font-size: 0.8rem;
   color: rgb(138, 137, 137);
   line-height: 1.5;
   margin-top: 1rem;
}

.PaymentDetail_detailsButtonWrapper__3vfpu {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
}

.PaymentDetail_backgroundImageInvoiceHistory__2zpuS {
   height: 200px;
   top: -80px;
   right: -90px;
   position: absolute;
}
.PaymentDetail_backgroundImageInvoiceHistory2__3At56 {
   height: 200px;
   bottom: -120px;
   left: -80px;
   position: absolute;
   -webkit-transform: rotate(60deg);
           transform: rotate(60deg);
}

@media (max-width: 406px) {
   .PaymentDetail_cardHeading__3upQn {
      font-weight: 500;
      margin: 0;
      font-size: 0.9rem;
   }
   .PaymentDetail_amount__3Mm95 {
      font-size: 1rem;
      font-weight: 700;
   }
   .PaymentDetail_cardDescription__450ly {
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 1.2;
   }
}

@media (max-width: 990px) {
   .PaymentDetail_home__IYULI {
      overflow-y: hidden !important;
   }
   .PaymentDetail_scroller__iaiwl {
      height: 80vh;
      overflow-y: scroll;
      scrollbar-color: rebeccapurple green;
      scrollbar-width: thin;
   }
}

@media (min-with: 1200) {
}

/*Payment*/
.PaymentDetail_payment__326rU {
   border: 2px solid green;
   /* height: 280px; */
   border-radius: 20px;
   padding: 2rem;
   background: #fff;
   /* background-color: red; */
}
.PaymentDetail_paymentError__2weFa {
   border: 2px solid red;
   /* height: 280px; */
   border-radius: 20px;
   padding: 2rem;
   background: #fff;
   /* background-color: red; */
}

.PaymentDetail_payment_header__2P7Rj {
   /* background: rgb(42, 41, 41); */

   background-color: white;
   padding-bottom: 20px;
   border-radius: 20px 20px 0px 0px;
}

.PaymentDetail_check__T-tw8 {
   margin: 0px auto;
   width: 5rem;
   height: 5rem;
   border-radius: 100%;
   background: #fff;
   text-align: center;
   background-color: green;
   color: white;
   position: relative;
}
.PaymentDetail_close__36rq5 {
   margin: 0px auto;
   width: 5rem;
   height: 5rem;
   border-radius: 100%;
   background: #fff;
   text-align: center;
   background-color: red;
   color: white;
   position: relative;
}

.PaymentDetail_checkicon__3_FaG {
   /* vertical-align: middle;
   height: 50px;
   display: flex;
   margin: 0 auto; */
   height: 3rem;
   width: 3rem;
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
   color: white;
}

.PaymentDetail_content__dptqA {
   text-align: center;
}

.PaymentDetail_content__dptqA h1 {
   font-size: 3rem;
   text-transform: uppercase;
   /* padding-top */
}

/* .content a {

} */

.PaymentDetail_content__dptqA > div > p {
   font-size: 1.1rem;
   margin: 1rem 0;
}
.PaymentDetail_content__dptqA a:hover {
   text-decoration: none;
   background: #000;
}

.PaymentDetail_goBackLink__Rw78-,
.PaymentDetail_goBackLink__Rw78-:hover {
   color: white !important;
   display: inline-block;
   padding: 0.5rem 1rem !important;
   margin-top: 1rem !important;
   font-size: 1.1rem;
   background-color: #12214a;

   /* width: 200px; */
   /* height: 35px; */
   /* color: #fff; */
   border-radius: 30px;
   /* padding: 5px 10px; */
   /* background: rgb(63, 63, 62); */
   transition: all ease-in-out 0.3s;
}
/*Invoice */

.PaymentDetail_invoicetable__1nSj7 {
   padding: 0.1rem 0.1rem !important;
}
.PaymentDetail_invoiceTablehead__6YOH4 {
   color: #566a7f;
   vertical-align: middle;
   text-transform: uppercase;
   font-size: 0.75rem;
   letter-spacing: 1px;
}
/*
Invoice Preview
*/
.PaymentDetail_docPage__133ys {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   /* padding-top: 1.8rem; */
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
}

.PaymentDetail_folderContainer__1S_F6 {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.PaymentDetail_folderContainer__1S_F6 > h4 {
   font-size: 1.2rem;
}
.PaymentDetail_fileStructurePreview__h9RoP {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.PaymentDetail_breadcrumbText__10wnr {
   color: black !important;
   padding-right: 5px;
}
a:hover {
   color: #12214a !important;
}

.PaymentDetail_totalAmount__3DkNc {
   font-weight: 700;
   text-align: right;
}
.PaymentDetail_topdiv__3EiBo {
   flex-direction: column;
}
.PaymentDetail_label1__34Gw0 {
   margin-bottom: 0;
   font-size: 12px;
   padding-top: -5px;
}

.PaymentDetail_showAmount__3g25C {
   font-size: 26px;
   text-transform: uppercase;
}
.PaymentDetail_amountText__24jBh {
   color: #696969;
   font-weight: 400;
   padding-right: 10px;
}
.PaymentDetail_termsandConditions__2wT7Y {
   color: blue !important;
   text-decoration: underline !important;
}

/* @import url('https://fonts.googleapis.com/css2?family=Nova+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap'); */

body {
   margin: 0;
   /*  */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   /* height: 100%; */
   width: 100vw;
   font-family: 'Noto Sans', sans-serif;
   overflow-x: hidden;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
div.dataTables_wrapper div.dataTables_length label {
   font-size: 12px;
}
.form-control-sm {
   padding: 0.25rem 0.8666666666rem !important;
}
div.dataTables_wrapper div.dataTables_filter label {
   font-size: 12px;
}
div.dataTables_wrapper div.dataTables_info {
   padding-top: 0.85em;
   font-size: 12px;
}
.page-item.disabled .page-link {
   font-size: 12px;
}
.page-item.active .page-link {
   font-size: 12px;
}
table.dataTable td,
table.dataTable th {
   font-size: 12px;
}
.badge {
   padding-left: 15px !important;
   padding-right: 15px !important;
   padding-top: 5px !important;
   padding-bottom: 5px !important;
   font-weight: 400 !important;
   font-size: 11px !important;
}
table.dataTable tbody tr.selected > * {
   box-shadow: inset 0 0 0 9999px rgb(245 246 247 / 95%);
   color: black;
}
table.dataTable.table-striped > tbody > tr.odd > * {
   box-shadow: inset 0 0 0 9999px rgb(245 246 247 / 95%);
}
table.dataTable tr.selected td.select-checkbox:before,
table.dataTable tr.selected th.select-checkbox:before {
   border: 1px solid black;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
   padding: 0em 0em;
   margin: 0px;
}
.tb {
   font-size: 12px;
}
.invoice {
   font-size: 12px;
}
a {
   color: #12214a !important;
}

