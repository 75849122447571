.docPage {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
}

.folderContainer {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.folderContainer > h4 {
   font-size: 1.2rem;
}

.cardPreview {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   background-color: white;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.breadcrumbText {
   color: black !important;
   padding-right: 5px;
}
a:hover {
   color: #12214a !important;
}
/* .contacycard {
   margin-bottom: 24px;
   box-shadow: 0 2px 3px #e4e8f0;
} */
.contactcard {
   border: 2px solid #293966 !important;
   margin-bottom: 11px !important;
}
.avatarMd {
   height: 4rem;
   width: 4rem;
}
.rounded-circle {
   border-radius: 50% !important;
}
.img-thumbnail {
   padding: 0.25rem;
   background-color: #f1f3f7;
   border: 1px solid #eff0f2;
   border-radius: 0.75rem;
}
.avatar-title {
   align-items: center;
   background-color: #3b76e1;
   color: #fff;
   display: flex;
   font-weight: 500;
   height: 100%;
   justify-content: center;
   width: 100%;
}
.bg-soft-primary {
   background-color: rgba(59, 118, 225, 0.25) !important;
}
a {
   text-decoration: none !important;
}
.badge-soft-danger {
   color: #f56e6e !important;
   background-color: rgba(245, 110, 110, 0.1);
}
.badge-soft-success {
   color: #63ad6f !important;
   background-color: rgba(99, 173, 111, 0.1);
}
.mb-0 {
   margin-bottom: 0 !important;
}
.badge {
   display: inline-block;
   padding: 0.25em 0.6em;
   font-size: 75%;
   font-weight: 500;
   line-height: 1;
   color: #fff;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: 0.75rem;
}
.contactName {
   font-size: 18px;
   /* padding-top: 27px; */
   margin-top: 9px;
   font-weight: 400;
   color: black;
   text-transform: uppercase;
   padding-bottom: 0px;
   margin-bottom: 0px;
}
.title {
   font-size: 14px;
   color: darkslategrey;
}
.contactDetail {
   color: #293966;
   font-size: 13px;
   letter-spacing: 0.4px;
}
