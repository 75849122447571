.docPage {
   background-color: #f4f7ff;
   display: inline-block;
   min-height: 100vh;
   z-index: 1;
   overflow: hidden;
   overflow-y: hidden;
   width: calc(100vw - 96px);
   margin-left: 80px;
   margin-right: 80px;
}

.folderContainer {
   font-family: 'Noto Sans', sans-serif;
   margin: 30px;
}

.folderContainer > h4 {
   font-size: 1.2rem;
}

.fileStructurePreview {
   /* height: 40vh; */
   width: 100%;
   padding: 2rem;
   background-color: #f4f7ff;
   border-radius: 10px;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.invoicetable {
   padding: 0.1rem 0.1rem !important;
}
.invoiceTablehead {
   color: #566a7f;
   vertical-align: middle;
   text-transform: uppercase;
   font-size: 0.75rem;
   letter-spacing: 1px;
}

.loadingComponent {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.loader {
   top: 50%;
   left: 50%;
   position: absolute;
   padding: 3rem;
   border-radius: 5px;
   background-color: white;
   transform: translate(-50%, -50%);
}

.loadingDescription {
   margin-top: 1rem;
}

/*Invoice Detail*/
.invoiceLogo {
   width: 100px !important;
   height: 100px;
}
.invoiceHeading {
   margin-bottom: 15px;
}
.invoiceAddress {
   padding-bottom: 0px;
   margin-bottom: 0px;
   font-size: 14px;
}

.invoiceCard {
   margin-bottom: 2px;
}
.invoiceDate,
.invoiceNumber,
.invoiceReference {
   margin-bottom: 0px;
   font-weight: 500;
   font-size: 14px;
}
.invoiceDateVal,
.invoiceNumberVal,
.invoiceReferenceVal {
   margin-bottom: 1px;
   padding-left: 10px;
   font-size: 14px;
}
.invoiceTable {
   font-size: 14px;
}
.invoiceTable > thead > tr > th {
   font-weight: 500;
   font-size: 14px;
}
.invoiceTable > tbody > tr > td {
   font-weight: 400;
   font-size: 14px;
}
.tableTopBorder {
   border-top: 1px solid black !important;
   border-top-width: 2px !important;
}
.bedgeInvoice {
   margin-left: 1rem;
   /* height: 100%; */
   /* background: red !important; */
   vertical-align: middle !important;
}
