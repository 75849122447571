@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Nova+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap'); */

body {
   margin: 0;
   /*  */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   /* height: 100%; */
   width: 100vw;
   font-family: 'Noto Sans', sans-serif;
   overflow-x: hidden;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
div.dataTables_wrapper div.dataTables_length label {
   font-size: 12px;
}
.form-control-sm {
   padding: 0.25rem 0.8666666666rem !important;
}
div.dataTables_wrapper div.dataTables_filter label {
   font-size: 12px;
}
div.dataTables_wrapper div.dataTables_info {
   padding-top: 0.85em;
   font-size: 12px;
}
.page-item.disabled .page-link {
   font-size: 12px;
}
.page-item.active .page-link {
   font-size: 12px;
}
table.dataTable td,
table.dataTable th {
   font-size: 12px;
}
.badge {
   padding-left: 15px !important;
   padding-right: 15px !important;
   padding-top: 5px !important;
   padding-bottom: 5px !important;
   font-weight: 400 !important;
   font-size: 11px !important;
}
table.dataTable tbody tr.selected > * {
   box-shadow: inset 0 0 0 9999px rgb(245 246 247 / 95%);
   color: black;
}
table.dataTable.table-striped > tbody > tr.odd > * {
   box-shadow: inset 0 0 0 9999px rgb(245 246 247 / 95%);
}
table.dataTable tr.selected td.select-checkbox:before,
table.dataTable tr.selected th.select-checkbox:before {
   border: 1px solid black;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
   padding: 0em 0em;
   margin: 0px;
}
.tb {
   font-size: 12px;
}
.invoice {
   font-size: 12px;
}
a {
   color: #12214a !important;
}
