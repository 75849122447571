.companydrop {
   margin-top: 10px;
}
.companydrop-btn {
   /* border-radius: 0px !important; */
   background-color: #f4f7ff !important;
   color: #25345f !important;
   font-weight: 500 !important;
   border: 2px solid !important;
}
.dropdown-company {
   border: 1px solid !important;
}
.company-nav-item {
   font-size: 14px;
   color: black;
}
.company-nav-item:active {
   font-size: 14px;
   color: black;
   background-color: darkgray !important;
}
