.card {
   box-shadow: 2px 3px 8px 1px #dfdfdf;
   width: auto;
   height: 210px;
   overflow: hidden;
   background-color: white;
   margin: 1rem 0.5rem;
   border-radius: 10px;
   position: relative;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   position: relative;
}
