/* Sujeevan design implementation */
.loginPage {
   height: 100vh;
   width: 100vw;
   display: flex;
   background-color: #1a2c5d28;
   justify-content: center;
   align-items: center;
   font-family: 'Noto Sans', sans-serif;
}

.loginContainer {
   background-color: aliceblue;
   border-radius: 10px;
   box-shadow: 3px 10px 9px -1px rgba(0, 0, 0, 0.1);
}

.header {
   background-color: #0b1431;
   padding-top: 2rem;
   padding-left: 3rem;
   padding-bottom: 1rem;
   color: white;
   border-radius: 10px 10px 0 0;
}

.header > h1 {
   font-size: 2rem;
}

.lockIconWrapper {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin-top: 1.5rem;
}

.lockIcon {
   width: 30px;
   margin-bottom: 0.3rem;
}

.iconCaption {
   font-size: 0.9rem;
}

.inputSection {
   padding: 0 3rem;
   padding-top: 1rem;
   padding-bottom: 1.5rem;
   display: flex;
   justify-content: center;
   flex-direction: column;
}

.inputSection > input {
   /* display: flex; */
   min-width: 300px;
   border: #0b1431a8 solid 2px;
   border-radius: 10px;
   padding: 0.6rem 1rem;
   outline: none;
   font-size: 0.8rem;
}

.inputSection > input:focus {
   border: #0b1431 solid 2px;
}

.inputSection > input:hover {
   border: #0b1431 solid 2px;
   cursor: pointer;
}

.passwordInput {
   margin-top: 1rem;
}

.errorText {
   color: red;
   padding: 0 3rem;
   margin-bottom: 1rem;
   font-size: 0.8rem;
}

.utilityContainer {
   display: flex;
   padding: 0 3rem;
   padding-bottom: 1.2rem;
   justify-content: space-between;
}

.rememberMe {
   cursor: pointer;
   font-size: 0.9rem;
   color: #454545;
}

.checkBox {
   accent-color: #12214a;
   margin-right: 0.5rem;
}

.forgotPasswordDiv > a {
   font-size: 0.9rem;
   color: rgb(161, 161, 161);
}

.buttonContainer {
   display: flex;
   justify-content: center;
}

.buttonContainer > button {
   border: none;
   background-color: #0b1431;
   color: white;
   padding: 0.4rem 1rem;
   border-radius: 10px;
   font-size: 1rem;
   margin-bottom: 1.5rem;
}

.horizontalLine {
   margin: 0 3rem;
   height: 3px;
   /* width: 100%; */
   background-color: rgb(156, 154, 154);
   border-radius: 10px;
   margin-bottom: 1rem;
}

.description {
   text-align: center;
   font-size: 0.8rem;
   padding: 1rem 3rem;
}

.loadingComponent {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
}

.loader {
   top: 50%;
   left: 50%;
   position: absolute;
   padding: 3rem;
   border-radius: 5px;
   background-color: white;
   transform: translate(-50%, -50%);
}

.loadingDescription {
   margin-top: 1rem;
}
